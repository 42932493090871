<template>
	<div class="app-container">
		<!-- filter -->
		<div class="filter-container">
			<!-- 关键字搜索 -->
			<div class="filter-item">
				<label class="label">关键字: </label>
				<el-input v-model="searchKey" style="width: 200px;" placeholder="角色名称"></el-input>
			</div>
			<!-- button -->
			<div class="filter-item">
				<el-button type="primary" style="margin-left:30px;width:90px;" @click="handleFilter">查询</el-button>
				<button-permissions :datas="'addRoles'">
					<el-button type="primary" style="margin-left:30px;width:100px;" @click="handleAddCoupon">添加</el-button>
				</button-permissions>
			</div>
		</div>
		<!-- table -->
		<div class="table-container">
			<el-table :data="tableData" v-loading="loading" style="width: 100%">
				<el-table-column prop="Name" label="角色名称"></el-table-column>
				<el-table-column prop="EmployeeCount" label="员工数量"></el-table-column>
				<el-table-column prop="AddTime" label="创建时间"></el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scop">

						<button-permissions :datas="'editRoles'">
							<div class="table-button" @click="handleTableEdit(scop.row.Id)">编辑</div>
						</button-permissions>

						<button-permissions :datas="'deleteRoles'">
							<div class="table-button" style="color:#F56C6C" @click="handleTableDelete(scop.row)">删除</div>
						</button-permissions>

					</template>
				</el-table-column>
			</el-table>
			<el-pagination v-if="page.total" style="margin-top:20px;float:right;" @size-change="handleSizeChange"
			 @current-change="handleCurrentChange" :current-page="page.current" :page-sizes="[10, 20, 30, 40, 50]" :page-size="page.size"
			 layout="total, sizes, prev, pager, next, jumper" :total="page.total">
			</el-pagination>
		</div>

	</div>
</template>

<script>
	
	import {employeerolelist, employeeroledelete} from '@/api/api.js';

	import buttonPermissions from '@/components/buttonPermissions';
	import config from '@/config/index'

	export default {
		components: {
			buttonPermissions
		},
		data() {
			return {
				searchKey: '',
				tableData: [],
				page: {
					total: 0,
					current: 1,
					size: 10
				},
				loading: false,

				goUrls: config.GO_URL,
			}
		},
		created() {
			this.getList();
		},
		methods: {
			// 获取列表数据
			async getList() {
				this.loading = true;
				try {
					let data = {
						KeyWords: this.searchKey, // 搜索关键字
						Skip: (this.page.current - 1) * this.page.size,
						Take: this.page.size
					}
					let result = await employeerolelist(data);

					this.page.total = result.Result.Total;
					this.tableData = result.Result.Results || [];

				} catch (error) {
					console.log(error);
				} finally {
					this.loading = false;
				}
			},
			handleFilter() {
				this.page.current = 1;
				this.getList()
			},
			handleAddCoupon() {
				this.$router.push({
					path: '/setting/roleManage/editRoleManage'
				})
			},
			handleTableEdit(id) {
				// console.log('id', id)
				this.$router.push({
					path: '/setting/roleManage/editRoleManage',
					query: {
						id: id
					}
				})
			},
			// table删除
			handleTableDelete(e) {
				// console.log(e.EmployeeCount)
				if(e.EmployeeCount <= 0){
						this.$confirm('是否确认删除这个角色？删除后不可恢复', '提示', {
							confirmButtonText: '确认删除',
							cancelButtonText: '关闭',
							type: 'warning'
						}).then(() => {
							
							this.sureDelete(e.Id);

						}).catch(() => {
						}).finally(() => {
						})
				}else{
					this.$confirm('该角色下存在在职员工，请先转移','',{
						confirmButtonText: '去转移',
						cancelButtonText: '关闭',
						customClass:'confirmclass',
						center: true
					}).then(()=>{
						let message = JSON.stringify(e)
						let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#' : ''
						let url = this.goUrls + headsUrls + '/setting/staffMange?message=' + message
						window.open(url)
					}).catch(() => {
					}).finally(() => {
					})
				}
			},
			
			async sureDelete(id){
				const res = await employeeroledelete({
					Id: id
				})
				
				if (res.IsSuccess){
					this.$message({showClose: true,
						message: '删除成功'
					})
					this.getList()
				}
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.page.size = val;
				this.getList();
			},
			// 翻页
			handleCurrentChange(val) {
				this.page.current = val;
				this.getList();
			}
		}
	}
</script>

<style lang="less" scoped>
	.app-container{
		background: #fff;
	}
	.table-button {
		color: #409EFF;
		display: inline-block;
		margin-right: 20px;
		cursor: pointer;
	}

	.dialog {
		width: 100%;

		::v-deep .el-dialog__header {
			border-bottom: 1px solid #ddd;
		}

		.content {
			padding: 0 30px;
			display: flex;
			flex-direction: row;

			.right {
				margin-left: 20px;
			}

			.text {
				margin-left: 20px;
				font-size: 14px;
				color: #999;
				line-height: 40px;
			}
		}

		.button {
			margin: 0 auto;
			width: 200px;
		}
	}

	.data-dialog {
		.value {
			line-height: 50px;
			font-size: 24px;
			color: #000;
		}

		.item {
			color: #666;
			margin-top: 10px;
		}
	}
	
</style>
<style  lang="less">
	.app-container{
		.el-message-box--center .el-message-box__header{
				padding-top: 24px !important;
			}
			.el-message-box__btns{
				padding-top: 16px;
				.el-button{
					width: 90px;
					height: 36px;
					font-size:14px ;
				}
			}
	}

</style>
